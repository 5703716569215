<template>
  <div>
    <h3> {{ p_pageInfo.caption }} </h3>
    <!-- <h6> <img src="@/icon/3037139.png" style="width: 2em;"> ***</h6> -->
    <template v-for="(page_info, page_info_index) in p_pageInfo.list">
      <b-row v-if="page_info.label">
        <b-col cols="1" style="text-align: right;">
          <img src="@/icon/1661274.png" style="width: 1.5em;">
        </b-col>
        <b-col cols="11">
          <div>{{ page_info.label }}</div>
        </b-col>
      </b-row>
      <b-row v-if="page_info.code">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <h6 class="code-text"> {{ page_info.code }} </h6>
        </b-col>
      </b-row>
      <b-row v-if="page_info.code_html">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <div class="code-text" v-html="page_info.code_html"></div>
        </b-col>
      </b-row>
      <b-row v-if="page_info.link">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <h6>
              <a :href="page_info.link" target="_blank">{{ page_info.link}}</a>
          </h6>
        </b-col>
      </b-row>
      <b-row v-if="page_info.iframe">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <iframe :src="page_info.iframe" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        </b-col>
      </b-row>
      <b-row v-if="page_info.mail">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <cite :title="page_info.mail"><a :href="'mailto:' + page_info.mail + '?Subject='" target="_top">{{ page_info.mail }}</a></cite>
        </b-col>
      </b-row>
      <b-row v-if="page_info.base64">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <img :src="page_info.base64" style="width: 100%;">
        </b-col>
      </b-row>
      <b-row v-if="page_info.img_link">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <img :src="page_info.img_link" style="width: 100%;">
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PageInfo',
  components: {},
  props: {
    p_pageInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>


